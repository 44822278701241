// Variables
@import "_variables";

.full-screen-billboard-video{
  position: relative;
  width:100%;
  height:100vh;
  overflow: hidden;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  video{
    object-fit: cover;
    width:100%;
    height:100%;
  }
  text-align: center;
  .video-content-container{
    position: absolute;
    width: 100%;
    top: 0;
  }
  &.left{
    h1, h2,h3{
      text-align: left;
    }
    .title-image > img{
      float:left;
    }
  }
  &.center{
    h1, h2,h3{
      text-align: center;
    }
    .title-image > img{
      margin:auto;
    }
  }
  &.right{
    h1, h2,h3{
      text-align: right;
    }
    .title-image > img{
      float:right;
    }
  }
  h2{
    margin: 0% 10%;
    margin-bottom: 0;
    //padding: 5%;
    padding-bottom: 1%;
    padding-left: 0%;
    text-align: left;
    font-weight: 900;
    font-size: 3.5rem;
    border-bottom: 2px solid #FFF;
  }

  &.light h2{
    color: #FFFFFF;
    border-color: #FFFFFF;
  }
  &.light h3{
    color: #FFFFFF;
  }
  &.dark h2{
    color: #000000;
    border-color: #000000;
  }
  &.dark h3{
    color: #000000;
  }

  .action-buttons{
    margin-left: 10%;
    margin-top: 5vh;
  }

  h3{
    text-align: left;
    padding-left: 0%;
    margin-left: 10%;
    font-size: 42px;
  }
  @media(max-width:767px){
    h3{
      margin-left: 0%;
    }
    h2{
      //margin: 20% 0;
      margin: 0% 0;
      margin-bottom: 0;
    }
    .action-buttons{
      margin-left: 0%;
      margin-top: 5vh;
    }
  }

}

*::-webkit-media-controls-panel {
  display: none!important;
  -webkit-appearance: none;
}

/* Old shadow dom for play button */

*::--webkit-media-controls-play-button {
  display: none!important;
  -webkit-appearance: none;
}

/* New shadow dom for play button */

/* This one works */

*::-webkit-media-controls-start-playback-button {
  display: none!important;
  -webkit-appearance: none;
}
