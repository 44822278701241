// Variables
@import "_variables";

/*.wrapper-super {
  background-color: #fff; }
.wrapper-light{
  background-color: #ffffff;
  color: #222222;
}
.wrapper-light h4:before{
  color: #222222;
}

.wrapper {
  h1, h2{
    margin-top: 0px;
  }

  p{
    font-size: 1.2em;
  }
}

.wrapper-primary {
  background-color: $primary_color; }

.wrapper-contrast {
  background-color: $primary_color;
  color: #000000; }

.wrapper-secondary {
  background-color: $secondary_color;
  color: #ffffff; }

.wrapper-dark {
  background-color: #222222;
  color: #eeeeee; }

.wrapper-hero {
  color: #ffffff;
  background-color: #eeeeee; }


.wrapper-sm .container, .wrapper-sm .container-fluid {
  padding-top: 50px;
  padding-bottom: 50px; }

.wrapper-md .container, .wrapper-md .container-fluid {
  padding-top: 75px;
  padding-bottom: 75px; }

.wrapper-lg .container, .wrapper-lg .container-fluid {
  padding-top: 100px;
  padding-bottom: 100px; }
*/
