@import "_variables";

.subnav{
  padding: 10px 25px;
  height: auto;
  /*background: rgba(255, 255, 255, 0.35);*/
  width: 100%;
  margin-bottom: 0;
}
.subnav li{
  display: inline;
}
.subnav li a{
  padding: 5px 15px;
}
.subnav li a.active{
  text-decoration: underline;
}
